import axios from "axios";

const enterWaitlist = async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/`,
        data,
        {
        }
      );
      return res;
    } catch (e) {
      console.log(e);
      return e;
    }
  };
  
  export { enterWaitlist };