import React from "react";
import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";

const AppRoutes = () => {
  let routesComponent;

  routesComponent = <PublicRoutes />;

  return <BrowserRouter>{routesComponent}</BrowserRouter>;
};

export default AppRoutes;
