import React from "react";
import { Route, Routes } from "react-router-dom";
import ComingSoon from "../Pages/Public/Coming";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ComingSoon />} />
      <Route path="*" element={<ComingSoon/>} />
    </Routes>
  );
};

export default PublicRoutes;