import AppRoutes from "./Routes/AppRoutes";
import "./App.css";
import ReactGA from "react-ga4";

ReactGA.initialize("G-JLKJP70Y71");
function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
