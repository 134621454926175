import React, { useState } from "react";
import { Input, Button, notification } from "antd";
import './style.css';
import logo from "../../../Assets/logo.png";
import { enterWaitlist } from "../../../API";

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
      placement: "topRight",
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onJoinClick = async () => {
    if (!email) {
      openNotification("warning", "無効な入力", "メールアドレスを入力してください。");
      return;
    }

    if (!validateEmail(email)) {
      openNotification("error", "無効なメールアドレス", "正しいメールアドレスを入力してください。");
      return;
    }

    setLoading(true);
    try {
      const res = await enterWaitlist({ email });

      if (res.status === 201) {
        openNotification("success", "成功", "ウェイトリストに正常に参加しました。");
        setEmail("");
      } else {
        openNotification("error", "エラー", "参加中に問題が発生しました。再度お試しください。");
      }
    } catch (error) {
      openNotification("error", "エラー", "サーバーとの接続に問題があります。");
    }
    setLoading(false);
  };

  return (
    <div className="coming-soon-container">
      <img src={logo} alt="Stynxio Logo" className="logo" />
      <h1 className="title">近日公開!</h1>
      <p className="description">AIベースのボイスチェンジャーをお楽しみに!</p>
      <p className="waitlist-detail">
        早期アクセスや特別割引の提供、アプリケーションの発売通知をお受け取りいただけます。
      </p>
      <div className="waitlist">
        <Input 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="メールアドレスを入力" 
          className="email-input"
        />
        <Button 
          type="primary" 
          className="join-button" 
          onClick={onJoinClick}
          loading={loading}
        >
          参加する
        </Button>
      </div>
      <footer className="footer">
        &copy; 2024 Stynxio. 全著作権所有。
      </footer>
    </div>
  );
};

export default ComingSoon;
